import React, { useEffect, useState } from "react";

import "./App.css";
import coverArt from "./img/album-time-will-tell.jpeg";
import pressPhoto from "./img/press-photo-web.jpg"

function App() {
  const [isLoaded, setLoaded] = useState(false);
  useEffect(() => {
    [coverArt, pressPhoto].forEach(picture => {
      const img = new Image();
      img.src = picture.fileName;
    });
  });

  return (
    <div
      className="App"
      style={{ visibility: isLoaded ? "visible" : "hidden" }}
    >
      <div className="bg-image">
        <img
          alt="Cover Art for Complete Walkthru - Time Will Tell"
          onLoad={() => setLoaded(true)}
          src={coverArt}
        />
      </div>
      <header>
        <div className="title-container">
          <h1 className="top-title">Complete Walkthru</h1>
          <h1 className="bottom-title">
            Time Will Tell
          </h1>
        </div>
        <p className="art-credit">
          2022
          <br />
          WTHRU011
          <br />
          artwork by <a href="https://www.ohdaniel.com/">Daniel Oh</a>
        </p>
      </header>
      <section class="main">
        <div class="cta-div">
          <a class="cta-btn" href="https://cw.ffm.to/timewilltell">Listen now</a>
        </div>
       <div className="bg-image-2">
        <img
          alt="For press"
          src={pressPhoto}
        />
      </div>
      </section>
      <footer>
        <div className="links">
          <ul className="social-links">
            <li className="buy-now">Follow Complete Walkthru:</li>
            <li>
              <a href="https://www.instagram.com/mcferrdog">
                Instagram
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCt7tA5kjzOh4VB4pL9MSaSw">
                YouTube
              </a>
            </li>
            <li>
              <a href="https://soundcloud.com/completewalkthru">Soundcloud</a>
            </li>
            <li>
              <a href="https://completewalkthru.bandcamp.com/">Bandcamp</a>
            </li>
          </ul>
        </div>
        <p className="copyright">
          {/* All tracks written, recorded and produced by Complete Walkthru */}
          © & ℗ Complete Walkthru Records 2022
        </p>
      </footer>
    </div>
  );
}

export default App;
